import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <figure className="kg-card kg-image-card kg-width-card">
            <Img
              fluid={data.playing.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption></figcaption>
          </figure>
          <p>
            Yang Lu received Doctor of Music Arts degree in Cello Performance
            from the University of Washington, a master’s degree from Carnegie
            Mellon University, and a bachelor’s degree from Central Conservatory
            of Music in Bejing, China. Her principal teachers include Sæunn
            Thorsteinsdóttir, Mingqing Yu, Eric Gaenslen, and David Premo. Lu
            was the recipient of the Alcor Endowed Scholarship and the Louis G.
            Marsh Endowed Music Scholarship in her DMA studies at the University
            of Washington, where she performed with the Corda Quartet, winner of
            the Strings and Piano Chamber Music Competition for the 2016 – 2017
            academic year. She has had masterclasses with world-renowned chamber
            quartets such as the Emerson String Quartet, the Brandis String
            Quartet, the Tokyo String Quartet, and the Calidore String Quartet.
            She also had solo masterclasses with eminent cellists that include
            Wolfgang Boettcher, Lynn Harrell, David Geringas, Walter Grimmer,
            Albert Rome, Solow Jeffery, and Xavier Phillips.
          </p>
          <p>
            As a dedicated cello teacher, Lu has developed a studio that strives
            to teach cello to students across an array of experiences, from
            beginners to advanced levels. Lu’s passionate commitment to her
            students’ success has helped them win many domestic and
            international competitions, including the David Popper VI
            International Cello Competition for Young Cellists, the 23rd Heran
            Violoncello Competition, the Liezen International Cello Competition,
            the Russian Chamber Music Competition, the Performing Arts Festival
            of the Eastside, and the Bach Festival. Her students have received
            admission to the Juilliard School, New England Conservatory of Music
            and to the University of Pennsylvania Department of Music. Although
            the fruits of her commitment to teaching have recently begun to
            appear, they bode well for her as she continues her journey as an
            artist-teacher.
          </p>
          <p>
            Always seeking to intersect technology with music, Lu believes that
            technology-assisted music education will be critical for the future
            because it will allow performers and students to practice with
            objectivity. As a music scholar, she undertook research into
            recorded excerpts of renown cellists in performance environments,
            analyzing those excerpts with digital signal processing techniques.
            This allowed her to visualize and discuss cello vibrato as it was
            actually executed. She will continue to create such innovate methods
            to improve musicians’ understanding of the instrument, in terms of
            both technique and musicality.
          </p>
          <p>
            As cellist and founding member of Seattle-based chamber music groups
            Trio Camellia and Corda Quartet, Lu enjoys the variegated life of a
            musician. She has been a recitalist and chamber musician both
            nationally and worldwide, appearing in performances at Carnegie
            Hall, Music Center at Strathmore, the National Centre for the
            Performing Arts, Tokyo Metropolitan Center, Hong Kong Cultural
            Centre, and Graz Opera in Austria. Yang served as cellist in many
            local orchestras including Seattle Philharmonic Orchestra, Seattle
            Collaborative Orchestra, Bellevue Ballet Orchestra, and Ensign
            Symphony Orchestra.
          </p>
          <p>
            Since moving to Seattle in 2013, Lu has devoted herself to musical
            outreach in the local community. She was invited to perform on
            Seattle’s KING 5 TV, which interviewed her on-air. Lu additionally
            performed with Trio Camelia for the project Music Under the Stars,
            hosted by Seattle Chamber Society, to offer chamber music to local
            Seattle communities. She was also a mentor at Seattle Music
            Partners, helping to eliminate racial and economic barriers to
            quality music education.
          </p>
          <p>
            Yang Lu serves as cello coach at the Seattle Youth Symphony
            Orchestra and as faculty at the Seattle Conservatory of Music, where
            she teaches chamber music. She is also a member of American String
            Teachers Association and Washington Music Teachers Association.
          </p>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    playing: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
